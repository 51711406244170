import { reaction } from 'mobx';

import { setCSSCustomProperty } from '../utils/css.utils';
import { useOnMount } from './lifecycle.hooks';

const handleElementRatio = (el: HTMLElement) => {
  if (!el) return;
  const w = el.clientWidth;
  const h = el.clientHeight;
  const ratio = w / h;
  const orientation = ratio >= 1 ? 'landscape' : 'portrait';
  el.setAttribute('data-element-width', w + 'px');
  el.setAttribute('data-element-height', h + 'px');
  el.setAttribute('data-element-ratio', ratio + '');
  el.setAttribute('data-element-orientation', orientation);
  setCSSCustomProperty('--element-width', w + 'px', el);
  setCSSCustomProperty('--element-height', h + 'px', el);
  setCSSCustomProperty('--element-ratio', ratio, el);
  setCSSCustomProperty('--element-orientation', orientation, el);
}

export const useHandleElementRatio = (observableRef: { current: HTMLElement | null }) => {
  useOnMount(() => {
    const d = reaction(
      () => observableRef.current,
      (el) => {
        if (!el) return;
        const handler = () => {
          handleElementRatio(el)
          // console.log("initRatioWatcher");
        };
        window.addEventListener('load', handler);
        window.addEventListener('resize', handler);
        return () => {
          window.removeEventListener('load', handler);
          window.removeEventListener('resize', handler);
        }
      }, { fireImmediately: true }
    )
    return d;
  })
}