const isDev = process.env.NODE_ENV === 'development';

export const onDocumentLoad = function (func) {
  if (!func || typeof func !== 'function') {
    if (isDev) console.error('A non-function is added to onDocumentLoad. This is an error and must be fixed. This is a development-only warning.');
    return;
  }
  document.addEventListener("DOMContentLoaded", func);
}

export default onDocumentLoad;