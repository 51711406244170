import { graphql } from 'gatsby';
import React from 'react';
import loadable from '@loadable/component';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import PageSectionSiteHero from '../components/WithQuery/PageSectionSiteHero/PageSectionSiteHero';
const PageSectionQuote = loadable(() => import('../components/PageSectionQuote/PageSectionQuote'));
const PageSectionEscapeToMarlboroughMap = loadable(() => import('../components/PageSectionEscapeToMarlboroughMap/PageSectionEscapeToMarlboroughMap'));
const SectionCategoryNavigation = loadable(() => import('../components/SectionCategoryNavigation/SectionCategoryNavigation'));
const PageSectionAssociations = loadable(() => import('../components/WithQuery/PageSectionAssociations/PageSectionAssociations'));
const PageSectionBackgroundSlides = loadable(() => import('../components/WithQuery/PageSectionBackgroundSlides/PageSectionBackgroundSlides'));
const PageSectionContentFromPages = loadable(() => import('../components/WithQuery/PageSectionContentFromPages/PageSectionContentFromPages'));
const PageSectionDiscover = loadable(() => import('../components/WithQuery/PageSectionDiscover/PageSectionDiscover'));
const PageSectionPictureCollageBanner = loadable(() => import('../components/WithQuery/PageSectionPictureCollageBanner/PageSectionPictureCollageBanner'));
const PageSectionServicesCards = loadable(() => import('../components/WithQuery/PageSectionServicesCards/PageSectionServicesCards'));
const PageSectionToursCards = loadable(() => import('../components/WithQuery/PageSectionToursCards/PageSectionToursCards'));
const PageSectionViewAlternator = loadable(() => import('../components/WithQuery/PageSectionViewAlternator/PageSectionViewAlternator'));
import { PageComponent } from '../types/gatsby.types';
import joinClassNames from '../utils/className.utils';
import './page-front-page.scss';
import APP_CONTENT_CONFIG from '../constants/appContentConfig.constants';

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      ...PageWithFeaturedImageFragment
      template {
        templateName
        ... on WpTemplate_FrontPage {
          templateName
          ...PageSectionSiteHeroFragment
          ...PageSectionBackgroundSlidesFragment
          ...PageSectionFrontPageContentSectionFragment
          ...PageSectionServicesCardsFragment
          ...PageSectionToursCardsFragment
          ...PageSectionPictureCollageBannerFragment
          ...PageSectionAssociationsFragment
        }
      }
    }
  }
`;

type PageFrontPageProps = {
  data: any,
}

const PageFrontPage: PageComponent<PageFrontPageProps> = (props) => {
  const {
    data: {
      wpPage: { id, title, content, slug, isFrontPage, author, template, seo },
    },
  } = props;

  const { frontPageFields } = template;
  const { sectionSiteHero, sectionServicesCards, sectionMap, sectionCategoryNavigation, sectionViewAlternator, sectionBackgroundSlide, sectionQuote, sectionToursCards, sectionDiscover, sectionPictureCollageBanner, sectionContentFromPages, sectionAssociations } = APP_CONTENT_CONFIG.page.frontPage;
  return (
    <PageTemplate
      {...props} // always include
      wpYoastSEO={seo}
      className={joinClassNames(`PageIndex`, slug)}
      pageSlug={slug}
      title={title}
      isFrontPage={isFrontPage}
    >

      {sectionSiteHero.enabled && <PageSectionSiteHero
        heroSlides={frontPageFields.heroSlides}
        Prequote={sectionSiteHero.content.Prequote}
        Quote={sectionSiteHero.content.Quote}
        Postquote={sectionSiteHero.content.Postquote}
        pointerText={sectionSiteHero.content.pointerText}
        bottomLeftLinkTagConfig={sectionSiteHero.content.cornerButtons.leftLinkTag}
        bottomRightLinkTagConfig={sectionSiteHero.content.cornerButtons.rightLinkTag}
        BottomBackground={sectionSiteHero.content.BottomBackground}
      />}

      {sectionServicesCards.enabled && frontPageFields.sectionServicesCards && <PageSectionServicesCards
        services={frontPageFields.sectionServicesCards}
      />}

      {sectionMap.enabled && <PageSectionEscapeToMarlboroughMap />}

      {sectionCategoryNavigation.enabled && <SectionCategoryNavigation />}

      {frontPageFields.sectionBackgroundSlide && <PageSectionBackgroundSlides
        bgSlides={frontPageFields.sectionBackgroundSlide?.[0]?.backgroundSlideImages}
        ArticleContent={sectionBackgroundSlide.ArticleContent}
      />}

      {sectionQuote.map((config, idx) => (
        config.enabled && <PageSectionQuote
          key={idx}
          className={config.content.sectionClassName}
          icon={config.content.blockquote.icon}
          iconSize={config.content.blockquote.iconSize}
          quote={config.content.blockquote.quote}
          quoteAuthor={config.content.blockquote.quoteAuthor}
          ArticleParagraphs={config.content.ArticleParagraphs}
          Logo={config.content.Logo}
        />
      ))}

      {sectionToursCards.enabled && frontPageFields.sectionToursCards && <PageSectionToursCards
        heading={frontPageFields.sectionToursCards?.[0].heading}
        body={frontPageFields.sectionToursCards?.[0].body}
        appIconName={frontPageFields.sectionToursCards?.[0].appIconName}
        tourProducts={frontPageFields.sectionToursCards?.[0].tourProducts}
      />}

      {sectionViewAlternator.enabled && <PageSectionViewAlternator
        contentSections={frontPageFields.contentSections}
        headerText={sectionViewAlternator.header.h1Text}
        headerButton={sectionViewAlternator.header.button}
      />}

      {sectionDiscover.enabled && <PageSectionDiscover
        row1Categories={sectionDiscover.categories.row1}
        row2Categories={sectionDiscover.categories.row2}
        header={{
          showAsCard: sectionDiscover.header.showAsCard,
          text: sectionDiscover.header.title,
          button: sectionDiscover.header.button,
        }}
        productConfig={sectionDiscover.productConfig}
      />}

      {sectionPictureCollageBanner.enabled && <PageSectionPictureCollageBanner
        pictureCollageSection={template.pictureCollageBannerSection}
        linkButtonsConfig={sectionPictureCollageBanner.buttons}
        Subheading={sectionPictureCollageBanner.Subheading}
      />}

      {sectionContentFromPages.enabled && <PageSectionContentFromPages />}

      {sectionAssociations.enabled && <PageSectionAssociations
        filmBodies={frontPageFields.sectionAssociations.filmBodies}
        nationalBodies={frontPageFields.sectionAssociations.nationalBodies}
        studios={frontPageFields.sectionAssociations.studios}
        suppliers={frontPageFields.sectionAssociations.suppliers}
      />}

    </PageTemplate>
  );
};

export default PageFrontPage;
