import './PageSectionSiteHero.scss';

import { graphql } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { useHandleElementRatio } from '../../../hooks/ratio.hooks';
import { useObservableRef } from '../../../hooks/useObservableRef.hook';
import { LinkTagConfig } from '../../../types/appContentConfig.types';
import { WpHeroSlide } from '../../../types/wordpress.types';
import { useProps, useStore } from '../../../utils/mobx.utils';
import BaseIcon from '../../BaseIcon/BaseIcon';
import BaseImage from '../../BaseImage/BaseImage';
import BaseLink from '../../BaseLink/BaseLink';
import watchVisibilityInViewport, { elementHasViewportVisibilityWatcher } from '../../../scripts/helpers/visible-in-viewport-watcher';
import { useOnMount } from '../../../hooks/lifecycle.hooks';
import { wait } from '../../../utils/waiters.utils';
import { Swiper, EffectFade, Thumbs, Autoplay, Navigation, FreeMode } from 'swiper';
import { Swiper as ReactSwiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { Nullable } from '../../../types/base.types';
import { action, flow } from 'mobx';
import joinClassNames from '../../../utils/className.utils';
import { useControllers } from '../../../controllers/app.controller';

export const PageSectionSiteHeroFragment = graphql`
  fragment PageSectionSiteHeroFragment on WpTemplate_FrontPage {
    frontPageFields {
      fieldGroupName
      heroSlides {
        fieldGroupName
        type
        title
        video {
          id
          uri
          title
          altText
          mediaItemUrl
        }
        image {
          ...Image_MediaItem
        }
        relatedImage {
          ...Image_MediaItem
        }
      }
    }
  }
`;

type PageSectionSiteHeroProps = {
  heroSlides: WpHeroSlide[],
  Prequote: React.ReactNode,
  Quote: React.ReactNode,
  Postquote: React.ReactNode,
  pointerText: string,
  bottomLeftLinkTagConfig: LinkTagConfig,
  bottomRightLinkTagConfig: LinkTagConfig,
  BottomBackground?: React.ReactNode,
}

const PageSectionSiteHero = (props: PageSectionSiteHeroProps) => {

  const { UI } = useControllers();

  const ref = useObservableRef<HTMLElement>();
  useHandleElementRatio(ref);

  const p = useProps(props);
  const SlideStates = {
    slideTransitionDuration: 1618,
    slideAutoplayInterval: 3280,
    onChangingSlide: [] as any[],
    onChangingToImageSlide: [] as any[],
  }
  const s = useStore(() => ({
    enableSwipers: !UI.onlyPhones,
    navSwiper: null as Nullable<Swiper>,
    gallerySwiper: null as Nullable<Swiper>,
    setNavSwiper: action((swiper: Swiper) => {
      s.navSwiper = swiper;
    }),
    setGallerySwiper: action((swiper: Swiper) => {
      s.gallerySwiper = swiper;
    }),
    currActiveIndex: 0 as number,
    init() {
      return new Promise(async (resolve, reject) => {
        try {
          s.initVideoCoverer();
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    get navSwiperConfig() {
      return {
        enabled: s.enableSwipers,
        onSwiper: s.setNavSwiper,
        modules: [Thumbs, Navigation, FreeMode],
        direction: 'vertical',
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 3,
        slideToClickedSlide: true,
        speed: SlideStates.slideTransitionDuration,
        watchSlidesProgress: true,
      } as SwiperProps;
    },
    get gallerySwiperConfig() {
      return {
        enabled: s.enableSwipers,
        onSwiper: s.setGallerySwiper,
        modules: [Autoplay, EffectFade, Thumbs],
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        slideToClickedSlide: true,
        speed: SlideStates.slideTransitionDuration,
        autoplay: {
          delay: SlideStates.slideAutoplayInterval,
          disableOnInteraction: false,
        },
        onSlideChange: flow(function* (swiper) {
          const { activeIndex } = swiper;
          s.currActiveIndex = activeIndex;
          s.navSwiper?.slideTo(activeIndex);
          const videoSlides = document.querySelectorAll('.home-hero-gallery__slide[data-slide-type="video"]');
          Array.from(videoSlides).forEach(async slide => {
            const video = slide.querySelector('video');
            if (!video) return;
            const index = +slide.getAttribute('data-slide-index')!;
            if (index === activeIndex) {
              try {
                // video.load();
                video.currentTime = 0;
                video.play();
              } catch (e) { }
            } else {
              // await wait(SlideStates.slideTransitionDuration);
              if (index !== activeIndex) {
                if (video.readyState >= 2 && !video.paused) video.pause();
              }
            }
          });
          while (SlideStates.onChangingSlide.length > 0) {
            const fn = SlideStates.onChangingSlide.shift();
            fn();
          }
          yield wait();
          const currentSlide = document.querySelector('.swiper-slide-active');
          const isImageSlide = currentSlide && currentSlide.getAttribute('data-slide-type') === 'image';
          if (!isImageSlide) return;
          while (SlideStates.onChangingToImageSlide.length > 0) {
            const fn = SlideStates.onChangingToImageSlide.shift();
            fn();
          }
        }),
        thumbs: {
          swiper: s.navSwiper,
        }
      } as SwiperProps;
    },
    initVideoCoverer() {
      const handler = () => {
        const videoSlides = document.querySelectorAll('.home-hero-gallery__slide[data-slide-type="video"]');
        Array.from(videoSlides).forEach(slide => {
          const video = slide.querySelector('video');
          if (!video) return;
          const checkVideoRatio = () => {
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;
            const videoRatio = videoWidth / videoHeight;
            const containerWidth = slide.clientWidth;
            const containerHeight = slide.clientHeight;
            const containerRatio = containerWidth / containerHeight;
            if (videoRatio >= containerRatio) {
              // video is wider or square; 100% height and auto width.
              video.setAttribute('data-fill-mode', 'fill-height');
            } else {
              video.setAttribute('data-fill-mode', 'fill-width');
            }
          };
          if (video.classList.contains('visible')) {
            checkVideoRatio();
          } else {
            if (elementHasViewportVisibilityWatcher(video)) return;
            watchVisibilityInViewport(video, {
              onEnter: () => {
                video.setAttribute('src', video.getAttribute('data-src')!);
                video.removeAttribute('data-src');
                video.onloadeddata = checkVideoRatio;
              }
            })
          }
        })
      }
      window.addEventListener('load', handler);
      window.addEventListener('resize', handler);
    },
  }))

  useOnMount(() => {
    s.init();
  })

  return <Observer children={() => (
    <section className="page-section site-hero">

      {p.heroSlides && (
        <section className="home-hero-gallery" id="home-hero-gallery" ref={ref}>
          <ReactSwiper {...s.gallerySwiperConfig} className="SiteHeroGallerySwiper">
            <figure className="home-hero-gallery__wrapper swiper-wrapper">
              {p.heroSlides.map((slide, idx) => (
                <SwiperSlide key={idx}>
                  <figure className="home-hero-gallery__slide swiper-slide" key={idx} data-slide-index={idx} data-slide-type={slide.type}>
                    <div className="home-hero-gallery__slide__inner">
                      {slide.type === 'image' && slide.image && <BaseImage
                        className="filler home-hero-gallery__slide__image-filler"
                        media={slide.image}
                        imageType='gatsbyDynamic'
                        loading="eager"
                        loadOnVisible={false}
                      />}
                      {slide.type === 'video' && slide.video && <video className="home-hero-gallery__slide__video" data-src={`${slide.video.mediaItemUrl}`} muted autoPlay playsInline loop />}
                      {slide.relatedImage && <BaseImage
                        className="SiteHeroRelatedImage"
                        media={slide.relatedImage}
                        imageType='gatsbyDynamic'
                        loading="eager"
                        loadOnVisible={false}
                        objectFit='contain'
                      />}
                    </div>
                  </figure>
                </SwiperSlide>
              ))}
            </figure>
          </ReactSwiper>
        </section>
      )}

      <div className="site-hero__inner">

        <header className="site-hero__header">

          <p className="site-hero__prequote">
            {p.Prequote}
          </p>

          <h1 className="site-hero__quote">
            {p.Quote}
          </h1>

          <div className="site-hero__postquote">
            {p.Postquote}
          </div>

        </header>

        {s.enableSwipers && p.heroSlides && (
          <section className="home-hero-gallery-nav-wrapper">
            <div className="home-hero-gallery-nav" id="home-hero-gallery-nav">
              <ReactSwiper {...s.navSwiperConfig} className="SiteHeroNavSwiper">
                <div className="home-hero-gallery__wrapper swiper-wrapper">
                  {p.heroSlides.map((slide, idx) => (
                    <SwiperSlide key={idx}>
                      <button key={idx} className={joinClassNames(`home-hero-gallery-nav__label`)}>
                        <span>{slide.title}</span>
                      </button>
                    </SwiperSlide>
                  ))}
                </div>
              </ReactSwiper>
            </div>
          </section>
        )}

        <div className="SiteHeroBottom">

          {p.bottomLeftLinkTagConfig.Label && <div className="site-hero__button-wrapper --bottomLeft">
            <BaseLink className="button" to={p.bottomLeftLinkTagConfig.href}>
              {p.bottomLeftLinkTagConfig.icon && <BaseIcon icon={p.bottomLeftLinkTagConfig.icon} size={p.bottomLeftLinkTagConfig.iconSize} />}
              <span>{p.bottomLeftLinkTagConfig.Label}</span>
            </BaseLink>
          </div>}

          {p.pointerText && <div className="site-hero__site-info">
            <span className="site-hero__site-info-pointer">
              <svg width="28" height="66" viewBox="0 0 28 66" fill="currentColor">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.7563 65.8014C13.9173 65.8254 14.0714 65.7278 14.12 65.5725C15.4811 61.2199 17.5201 57.3319 19.8409 54.3502C22.1235 51.4176 24.6571 49.3884 27.0621 48.6274C27.2204 48.5773 27.3144 48.4123 27.2704 48.2522C27.2263 48.0921 27.0608 47.9975 26.9023 48.0472C24.327 48.8547 21.6922 50.9926 19.3663 53.9808C17.7767 56.0232 16.3212 58.4754 15.1184 61.2021C14.8919 61.7156 14.0495 61.558 14.0495 60.9967L14.0495 0.0817303C14.0495 0.0365916 14.0129 -5.79979e-07 13.9677 -5.81952e-07L13.4481 -6.04668e-07C13.4029 -6.06641e-07 13.3663 0.0365915 13.3663 0.0817302L13.3663 60.9967C13.3663 61.558 12.5239 61.7156 12.2974 61.2021C11.0946 58.4754 9.63914 56.0232 8.04947 53.9808C5.72359 50.9926 3.08883 48.8547 0.513481 48.0472C0.355017 47.9975 0.189483 48.0921 0.145414 48.2522C0.101345 48.4123 0.195343 48.5773 0.353678 48.6274C2.75865 49.3884 5.29231 51.4176 7.57488 54.3502C9.89572 57.3319 11.9347 61.2199 13.2958 65.5725C13.3444 65.7278 13.4985 65.8254 13.6595 65.8014C13.6916 65.7966 13.7242 65.7966 13.7563 65.8014Z" />
            </svg>
            </span>
            <p>{p.pointerText}</p>
          </div>}

          {p.bottomRightLinkTagConfig.Label && <div className="site-hero__button-wrapper --bottomRight">
            <BaseLink className="button primary" to={p.bottomRightLinkTagConfig.href}>
              {p.bottomRightLinkTagConfig.icon && <BaseIcon icon={p.bottomRightLinkTagConfig.icon} size={p.bottomRightLinkTagConfig.iconSize} />}
              <span>{p.bottomRightLinkTagConfig.Label}</span>
            </BaseLink>
          </div>}

        </div>

        {p.BottomBackground && <div className='SiteHeroBottomBackground'>
          {p.BottomBackground}
        </div>}

      </div>

    </section>
  )} />;
};

export default PageSectionSiteHero;
