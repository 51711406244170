import { isBrowser } from '../../env';
import onDocumentLoad from './on-document-load';

let scrollTop = 0;
const elementsToCheck = [];

const isBot = isBrowser
  ? document.documentElement.classList.contains("bot")
  : false;

export function watchVisibilityInViewport(_els, options = {}) {
  try {
    let els;
    if (typeof _els === "string") {
      els = document.querySelectorAll(_els);
    } else if (_els instanceof HTMLElement) {
      els = [_els];
    } else if (
      typeof _els[Symbol.iterator] === "function" &&
      _els[0] instanceof HTMLElement
    ) {
      els = [..._els];
    } else {
      throw TypeError(
        "watchVisibilityInViewport first argument must either be a selector, a DOM elemnt or an array/iterable of DOM elements."
      );
    }
    elementsToCheck.push(
      ...Array.from(els).map((el) => {
        if (options.delay) {
          el.style.transitionDelay = options.delay + "ms";
          el.setAttribute(
            "data-offset-top",
            el.getBoundingClientRect().top + scrollTop
          );
        }
        return { el, options };
      })
    );
    handleScroll();
  } catch (e) {
    console.warn("watchVisibilityInViewport is unable to select", _els, e);
  }
}

export const checkElementVisibility = (elementToCheck, scrollTop, vh) => {
  const { el, options } = elementToCheck;
  const {
    offset = 0,
    visibleClass = "visible",
    invisibleClass = "invisible",
    repeat = false,
    onEnter,
    onExit,
  } = options;
  let offsetTop = el.getAttribute("data-offset-top");
  if (!offsetTop) {
    offsetTop = el.getBoundingClientRect().top + scrollTop;
  }
  offsetTop = +offsetTop;
  const visible =
    isBot || // top is before viewport ends plus offset
    (offsetTop < scrollTop + vh + offset &&
      // bottom is after viewport begins minus offset
      offsetTop + el.clientHeight > scrollTop - offset);
  // console.log(visible);
  if (visible) {
    // console.log('visible');
    if (el.classList.contains(visibleClass)) {
      el.classList.remove(invisibleClass);
    } else {
      el.classList.add(visibleClass);
      onEnter && onEnter();
    }
    // console.log(repeat ? 'repeat' : 'no-repeat');
    if (!repeat) {
      const i = elementsToCheck.indexOf(elementToCheck);
      if (i >= 0) {
        elementsToCheck.splice(i, 1);
      }
    }
  } else {
    if (!el.classList.contains(invisibleClass)) {
      el.classList.add(invisibleClass);
      onExit && onExit();
    } else {
      el.classList.remove(visibleClass);
    }
  }
};

const handleScroll = () => {
  // console.log('handleScroll');
  scrollTop =
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop;
  const vh = window.innerHeight;
  if (elementsToCheck.length === 0) {
    window.removeEventListener("scroll", handleScroll);
    return;
  }
  for (let elementToCheck of elementsToCheck) {
    checkElementVisibility(elementToCheck, scrollTop, vh);
  }
};
const handleResize = () => {
  elementsToCheck.forEach((el) => {
    el.el.setAttribute(
      "data-offset-top",
      el.el.getBoundingClientRect().top + scrollTop
    );
  });
};

function addWatcherToElements() {
  handleResize();
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
}
export function elementHasViewportVisibilityWatcher(el) {
  return elementsToCheck.includes(el);
}

if (isBrowser) onDocumentLoad(addWatcherToElements);

export default watchVisibilityInViewport;
